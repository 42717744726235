import { memo, PropsWithChildren } from 'react'
import { Icon } from '@library/components/atoms'
import { IconName } from '@library/components/atoms/Icon/utils'
import { Size } from '@library/types'
import { transitionClasses } from '@library/utils'
import clsx from 'clsx'

import { getBackgroundClasses, getDisabledClasses, getSizeClasses, getTextClasses } from './utils'

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  text?: string
  leftIcon?: IconName
  rightIcon?: IconName
  mode: 'accent' | 'danger' | 'positive' | 'warning'
  appearance: 'primary' | 'subtle' | 'ghost' | 'outline'
  size: Size
  iconColor?: string
  testId?: string
  iconViewBox?: string
}

export const Button = memo(
  ({
    className,
    text,
    rightIcon,
    leftIcon,
    mode,
    appearance,
    size,
    iconColor,
    testId,
    iconViewBox,
    ...props
  }: PropsWithChildren<ButtonProps>): JSX.Element => {
    const sizeClasses = getSizeClasses(size)
    const modeClasses = getTextClasses(mode, appearance)
    const backgroundClasses = getBackgroundClasses(mode, appearance) + transitionClasses

    return (
      <button
        {...props}
        data-testid={testId}
        className={clsx(
          'box-border inline-flex items-center justify-center gap-2 relative font-medium',
          props.disabled ? getDisabledClasses(appearance) : backgroundClasses,
          sizeClasses,
          modeClasses,
          appearance === 'outline' && 'ring-borderNeutralDefault ring-1',
          className,
        )}
      >
        {leftIcon && (
          <Icon
            color={iconColor || 'text-current'}
            iconName={leftIcon}
            size={size}
            viewBox={iconViewBox}
            className={leftIcon === 'loader' ? 'animate-spin' : undefined}
          />
        )}
        {text && text}
        {rightIcon && (
          <Icon
            color={iconColor || 'text-current'}
            iconName={rightIcon}
            size={size}
            viewBox={iconViewBox}
            className={rightIcon === 'loader' ? 'animate-spin' : undefined}
          />
        )}
      </button>
    )
  },
)

Button.displayName = 'Button'
